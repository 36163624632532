import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ColDef } from 'ag-grid-enterprise';
import 'ag-grid-enterprise';
import {
  ColumnResizedEvent,
  GridApi,
  GridReadyEvent,
  IsRowSelectable,
  Module,
  RowClickedEvent,
  RowNode,
  RowGroupingDisplayType,
  FirstDataRenderedEvent,
} from 'ag-grid-community';
import { CustomTooltipComponent } from './custom-tooltip/custom-tooltip.component';
import { Subscription } from 'rxjs';
import { CommunicationService } from '../../services/communication.service';
import { AppConst } from '@app/App.const';
import { CustomDateComponent } from '../custom-date/custom-date.component';
import { getPaginationSize, setPaginationSize } from '../../utils/ag-grid';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnDestroy {
  public tooltipShowDelay: any;
  public frameworkComponents: any;
  public currentPage = 3;
  public maxPages = 5;
  @Input() showTable = true;
  @Input() columnDefs: ColDef[] = [];
  @Input() rowData: any[] = [];
  @Input() rowClassRules: any = {};
  @Input() defaultColDef: ColDef = {
    flex: 1,
    minWidth: 100,
    resizable: true,
    autoHeight: true,
    sortable: true,
    floatingFilter: true,
  };
  public showMenu: boolean = true;
  @Input() suppressRowClickSelection = true;
  @Input() enableRangeSelection=false;
  @Input() groupRowRendererParams = {};
  @Input() rowSelection:"single" | "multiple" = "multiple"; ;
  @Input() suppressAggFuncInHeader = true;
  @Input() suppressCellSelection = false;
  @Input() paginationRequired = true;
  @Input() masterDetail = false;
  @Input() detailCellRendererParams = {};
  @Input() paginationPageSize=AppConst.paginationPageSize;
  @Input() isRowMaster = () => {
    return false;
  };
  @Input() defaultPage = 0;
  @Input() pageChangeHandler = (page:any) => {};
  @Input() overlayNoRowsTemplate = 'No Data Found';
  @Input() pageSizeSelection=false
  @Input() tableViewFullSizeEnabled=false

  @Output() cellClicked = new EventEmitter<any>();
  @Output() gridReady = new EventEmitter<any>();
  @Output() rowClicked = new EventEmitter<any>();
  @Output() columnResized = new EventEmitter<any>();
  @Output() selectionChanged = new EventEmitter<any>();
  @Output() cellEditingStopped = new EventEmitter<any>();
  @Output() cellValueChanged=new EventEmitter<any>();

  public overlayLoadingTemplate = `<span class="ag-overlay-loading-center">Loading...</span>`;
  public rowGroupPanelShow: any = 'always';
  public groupDisplayType: RowGroupingDisplayType = 'groupRows';
  public modules: Module[] = [];
  public columnApi: any;
  public gridApi!: GridApi;
  public paginationPageSizeSelector: number[] | boolean = [50, 100, 150 ,200];
  public searchSubscribtion = new Subscription();
  public filterSubscription = new Subscription();
  @Input() isRowSelectable: IsRowSelectable<any> | undefined = (
    params: RowNode<any>
  ) => {
    return !!params.data;
  };

  constructor(public communicationService: CommunicationService) {
    this.tooltipShowDelay = 0;
    this.frameworkComponents = { customTooltip: CustomTooltipComponent ,agDateInput: CustomDateComponent};
  }
  ngOnInit(): void {
    this.searchSubscribtion =
      this.communicationService.isTableOperationsEventCalled.subscribe(
        (res: { event: string; data: any }) => {
          switch (res.event) {
            case 'downloadExcel':
              this.gridApi.exportDataAsExcel(res.data);
              break;
            case 'search':
              this.gridApi.setQuickFilter(res.data);
              break;
            case 'showLoader':
              this.gridApi?.showLoadingOverlay && this.gridApi.showLoadingOverlay();
              break;
            case 'hideLoader':
              this.gridApi?.hideOverlay && this.gridApi?.hideOverlay();
              break;
          }
        }
      );
    this.filterSubscription=this.communicationService.filterChangedEvent.subscribe((res) => {
      this.gridApi?.setFilterModel && this.gridApi.setFilterModel(res);
      //this.gridApi.onFilterChanged();
    }); 
    if(this.pageSizeSelection){
      this.paginationPageSize=Number(getPaginationSize() || 100)
    } 
  }
  //in onGridReady, store the api for later use
  public onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridReady.emit(params);
    if(this.pageSizeSelection){
      const pageSize=Number(getPaginationSize() ? getPaginationSize() : 100)
      this.gridApi.paginationSetPageSize(pageSize)
    }
  }

  filterChanged(event: any) {
    this.communicationService.onFilterChange(this.gridApi.getFilterModel() || {});
  }

  public onRowClicked(params: RowClickedEvent) {
    this.rowClicked.emit(params);
  }

  public onColumnResized(params: ColumnResizedEvent) {
    this.columnResized.emit(params);
  }

  public onSelectionChanged(params: any) {
    this.selectionChanged.emit(params);
  }

  public changePageSize(event:any){
    const pageSize=event?.target?.value ? event?.target?.value : 100
    this.gridApi.paginationSetPageSize(pageSize)
    setPaginationSize(pageSize)
    this.paginationPageSize=pageSize
  }

  ngOnDestroy() {
    this.searchSubscribtion.unsubscribe();
    this.filterSubscription.unsubscribe();
  }

  public editCell(event: any) {
    //for future reference
  }
  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.paginationGoToPage(this.defaultPage);
  }
  onPaginationChanged(event: any) {
    if(event.newPage){
      this.pageChangeHandler(event.api.paginationGetCurrentPage() || 0);
    }
  }

  public onCellEditingStopped(event: any) {
    this.cellEditingStopped.emit(event);
  }

  public onCellValueChanged(event: any) {
    this.cellValueChanged.emit(event);
  }

  public onCellClicked(event: any) {
    this.cellClicked.emit(event);
  }

  public onRowGroupOpened(params: any) {
    if (params?.node?.expanded) {
      this.gridApi.forEachNode(function (node) {
        if (
          node.expanded &&
          node.id !== params.node.id &&
          node.uiLevel === params.node.uiLevel
        ) {
          node.setExpanded(false);
        }
      });
    }
  }
}
