import { DatePipe } from '@angular/common';
import * as CryptoJS from 'crypto-js';
import { TemplateRef } from '@angular/core';
import { AppConst } from '@app/App.const';
import { CellRendererComponent } from '../components/table/cell-renderer/cell-renderer.component';
import { moreActionsEnum, workpaperActionsEnum } from './app-enum';
import moment from 'moment';
import { dateSortingComparator } from './ag-grid';

const displayNames: any = (val: string) => {
  switch (val) {
    case 'dtDashboardCalculationTab':
      return {
        calculationName: 'Calculation File',
        processingDate: 'Processing Date',
        fileStatus: 'Status',
      };
      break;
    case 'dtDashboardWorkpaperTab':
      return {
        workpaperName: 'Workpaper File',
        processingDate: 'Processed Date',
        workpaperStatusName: 'Workpaper Status',
        isReviewed: 'Reviewed',
      };
      break;
    case 'auditorsDashWorkpaperTab':
      return {
        workpaperName: 'Workpaper File',
        processingDate: 'Processed Date',
        auditorDBWorkpaperStatusName: 'Status',
      };
      break;
    case 'identificationTabDtDashboard':
      return {
        inputFileType: 'Input File',
        receivedDateTime: 'Received Date',
      };
    case 'customMappingList':{
      return {
        tpaName:'Administrator',
        mappingType:'Type',
        type:'Mapping Kind',
        customMappingName:'Mapping Name',
        clientName:'Client',
        updatedDate:'Last Edit Date',
        createdBy:'Owner',
        updatedBy:'Last Updated By',
      }
    }
    case 'childFundTab':{
      return {
        fundCode: 'Fund Code',
        fundName: 'Fund Name',
        clientName: 'Client Name',
      }
    }
    case 'ApplyMapping':{
      return{
        'appliedAccountMapping':'Account Mapping',
        'appliedInstrumentMapping':'Instrument Mapping',
        'appliedShareCapitalMapping':'Share Capital Mapping',
        'appliedTransactionMapping':'Transaction Mapping',
        'appliedFxRates':'FX Rates',
        'financialEndDate':'Period End',
        'clientName':'Client',
      }
    }
    default:
      return {
        tpaName: 'Administrator',
        fundCountry: 'Country',
        financialEndDate: 'Period End',
        fundRequestStatus: 'Status',
        isSelfOnBoarded: 'Self-Onboarded',
        clientName: 'Client',
        fundAlternateCodes: 'Alternate Codes',
        userName: 'User',
        activityDate: 'Date',
        activityName: 'Activity',
        workPaperStats: 'Work Papers',
        cdmTableName: 'CDM File',
        cyValidationStatus: 'Validation CY',
        pyValidationStatus: 'Validation PY',
        receivedStatus: 'Status',
        gfisClientCode:'GFIS Client Code',
        navCloseDate:'NAV Close Date',
        fy:'FY',
        dateDue:'Data Due'
      };
  }
};

const fileDownload = (fileName: string, blob: any) => {
  let downloadLink = document.createElement('a');
  downloadLink.href = window.URL.createObjectURL(blob);
  if (fileName) downloadLink.setAttribute('download', fileName);
  document.body.appendChild(downloadLink);
  downloadLink.click();
  downloadLink.remove();
};

const getDateFormat = () => {
  return localStorage.getItem('regionDateFormat')??'dd/mm/yyyy';
}

// const dateFormator = (date: any) => {
//   let datePipe = new DatePipe('en-US');
//   return datePipe.transform(date.value, 'dd/MM/yyyy')?.toString();
// };


const dateConverter = (date: {value:any}) => {
  const regionDateFormat=getDateFormat()
  //may need to write a verification for date parameter type to be dd/MM/yyyy
  let formattedDate=date?.value
  if(formattedDate){
    const day=formattedDate?.split('/')[0];
    const month=formattedDate?.split('/')[1];
    const year=formattedDate?.split('/')[2];
    switch(regionDateFormat.toUpperCase()){
      case 'DD/MM/YYYY': {
          formattedDate=`${day}/${month}/${year}`;
          break;
        }
      case 'MM/DD/YYYY': {
          formattedDate=`${month}/${day}/${year}`
          break;
        };
      case 'YYYY/MM/DD': {
          formattedDate=`${year}/${month}/${day}`
          break;
        };
      default: return `${day}/${month}/${year}`;
    }
  }
  return formattedDate;
};

//This function is used to convert date to dd/MM/yyyy format
const dateConverterToDDMMYYYY=(date: any)=> {
  const regionDateFormat=getDateFormat()
  const first=date?.split('/')[0];
  const second=date?.split('/')[1];
  const third=date?.split('/')[2];

  let formattedDate=date;

  switch(regionDateFormat.toUpperCase()){
    case 'DD/MM/YYYY': {
        formattedDate=date;
        break;
      }
    case 'MM/DD/YYYY': {
        formattedDate=`${second}/${first}/${third}`
        break;
      };
    case 'YYYY/MM/DD': {
        formattedDate=`${third}/${second}/${first}`
        break;
      };
    default: formattedDate=date;
  }
  return formattedDate
}

// This function is used to convert date to region date format
const dateTimeFormatter = (date: {value:any}) => {
  const dateFormat=getDateFormat().toUpperCase() || 'DD/MM/YYYY';
  const result=moment.utc(date.value)?.local()?.format(`${dateFormat} HH:mm:ss`)
  if(result.toLowerCase()==='invalid date'){
    return date.value
  }
  return result;
};

// const dateFormatorCustomDateTime = (
//   date: any,
//   customFormat: string = 'MMM dd YYYY hh:mmaa'
// ) => {
//   let datePipe = new DatePipe('en-US');
//   let formattedDate: any;
//   try {
//     formattedDate = datePipe.transform(date.value, customFormat)?.toString();
//   } catch {
//     formattedDate = date;
//   }
//   return formattedDate;
// };

const checkError = (data: any) => {
  return !!data.error;
};

const returnColumnDef = (
  val: any,
  index: number | null = null,
  rowGroup: boolean = false,
  template?: TemplateRef<any>,
  style?: any,
  isEditable = false,
  colDefFor: string = '',
  width=150
) => {
  const colDef= index == 0 && index !== null
  ? {
      field: val,
      headerValueGetter: (params: any) => {
        return displayNames(colDefFor)[val]
          ? displayNames(colDefFor)[val]
          : camelCaseToTitleCase(val);
      },
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      headerTooltip: getHeader(val),
      checkboxSelection: true,
      width:width,
      rowGroup: rowGroup,
      editable: isEditable,
      enableRowGroup: true,
      sortingOrder: ['desc', 'asc'],
      filter: 'agMultiColumnFilter',
    }
  : template
  ? {
      field: val,
      headerValueGetter: (params: any) => {
        return displayNames(colDefFor)[val]
          ? displayNames(colDefFor)[val]
          : camelCaseToTitleCase(val);
      },
      headerTooltip: getHeader(val),
      cellRenderer: CellRendererComponent,
      cellRendererParams: {
        ngTemplate: template,
        style: style,
        colDefFor:colDefFor
      },
      editable: isEditable,
      sortingOrder: ['desc', 'asc'],
      enableRowGroup: true,
      width:width,
      filter: 'agMultiColumnFilter',
    }
  : {
      field: val,
      headerValueGetter: (params: any) => {
        return displayNames(colDefFor)[val]
          ? displayNames(colDefFor)[val]
          : camelCaseToTitleCase(val);
      },
      headerTooltip: getHeader(val),
      editable: isEditable,
      enableRowGroup: true,
      sortingOrder: ['desc', 'asc'],
      width:width,
      filter: 'agMultiColumnFilter',
    };
  if(colDefFor=='dtDashboardFundlist'){
    colDef['checkboxSelection']=false
  }
  if(colDef.field.toUpperCase().indexOf('DATE') !== -1){
    const colDefForDates:any=colDef
    colDefForDates['comparator']=dateSortingComparator
    return colDefForDates
  }
  return colDef;
};

const removeSpace = (inp: string) => {
  return inp ? inp.split(' ').join('') : '';
};

const isObjectEmpty = (obj: Object) => {
  return Object.keys(obj).length === 0;
};

const encryptData = (data: any) => {
  const key = CryptoJS.enc.Utf8.parse(AppConst.SESSION_ENCRYPTION_KEY);
  const iv = CryptoJS.enc.Utf8.parse(AppConst.SESSION_ENCRYPTION_IV);
  const encrypted = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(data.toString()),
    key,
    {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  return encrypted;
};

const decryptData = (encryptedData: any) => {
  const key = CryptoJS.enc.Utf8.parse(AppConst.SESSION_ENCRYPTION_KEY);
  const iv = CryptoJS.enc.Utf8.parse(AppConst.SESSION_ENCRYPTION_IV);
  if (encryptedData != null) {
    let decrypted = CryptoJS.AES.decrypt(encryptedData, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
  return null;
};

function camelCaseToTitleCase(in_camelCaseString: any) {
  const result = in_camelCaseString
    .replace(/(_)+/g, ' ')
    .replace(/([a-z])([A-Z][a-z])/g, '$1 $2')
    .replace(/([A-Z][a-z])([A-Z])/g, '$1 $2')
    .replace(/([a-z])([A-Z]+[a-z])/g, '$1 $2')
    .replace(/([A-Z]+)([A-Z][a-z][a-z])/g, '$1 $2')
    .replace(/([a-z]+)([A-Z0-9]+)/g, '$1 $2')
    .replace(/([A-Z]+)([A-Z][a-rt-z][a-z]*)/g, '$1 $2')
    .replace(/([0-9])([A-Z][a-z]+)/g, '$1 $2')
    .replace(/([A-Z]{2,})([0-9]{2,})/g, '$1 $2')
    .replace(/([0-9]{2,})([A-Z]{2,})/g, '$1 $2')
    .trim();
  return result.charAt(0).toUpperCase() + result.slice(1);
}

const workpaperActionItems = [
  {
    name: 'Rerun',
    icon: '/assets/icons/av/ic_replay_24px.svg',
    id: workpaperActionsEnum.Rerun,
  },
  {
    name: 'Release All',
    icon: '/assets/icons/content/ic_content_copy_24px.svg',
    id: workpaperActionsEnum.ReleaseAll,
  },
  {
    name: 'Unrelease All',
    icon: '/assets/icons/navigation/ic_cancel_24px.svg',
    id: workpaperActionsEnum.UnRealeaseAll,
  },
];

const moreFRActions = [
  {
    desc:'enableExternalValuation',
    name: 'Enable External Valuation',
    icon: '/assets/icons/hardware/ic_sim_card_24px.svg',
    id: moreActionsEnum.enableExternalValuation,
  },
  {
    desc:'disableExternalValuation',
    name: 'Disable External Valuation',
    icon: '/assets/icons/communication/ic_no_sim_24px.svg',
    id: moreActionsEnum.disableExternalValuation,
  },
  {
    desc:'reset',
    name: 'Reset',
    icon: '/assets/icons/action/ic_restore_page_24px.svg',
    id: moreActionsEnum.reset,
  },
  {
    desc:'delete',
    name: 'Delete',
    icon: '/assets/icons/action/ic_delete_24px.svg',
    id: moreActionsEnum.delete,
  },
];

const recipientRoles={
  'PPED':'pped',
  'Auditor':'auditor',
  'Client':'client',
  'Administrator':'administrator'
}

const getRecipientDisabledStatus = (roleName: string, recipients: any,isAuditorDasboard=false) => {
  if(!roleName){
    return false
  }
  if(isAuditorDasboard && roleName.toLowerCase()!==recipientRoles.Auditor){
    return true
  }
  switch(roleName.toLowerCase()){
    case recipientRoles.PPED:
      return recipients?.some((recipient:any)=>recipient.role.toLowerCase()===roleName.toLowerCase())
    case recipientRoles.Auditor:
      const auditorList=recipients?.filter((recipient:any)=>recipient.role.toLowerCase()===roleName.toLowerCase())
      return auditorList.length>=4
    case recipientRoles.Client:
      return false
    case recipientRoles.Administrator:
      const administratorList=recipients?.filter((recipient:any)=>recipient.role.toLowerCase()===roleName.toLowerCase())
      return administratorList.length>=3
    default:
      return false
  }
}

const getRecipientDisabledMessage=(roleName: string,isAuditorDasboard=false)=>{
  if(isAuditorDasboard && roleName.toLowerCase()!==recipientRoles.Auditor){
    return ''
  }
  switch(roleName.toLowerCase()){
    case recipientRoles.PPED:
      return 'Maximum number of PPED role cannot exceed 1'
    case recipientRoles.Auditor:
      return 'Maximum number of Auditor role cannot exceed 4'
    case recipientRoles.Client:
      return ''
    case recipientRoles.Administrator:
      return 'Maximum number of Administrator role cannot exceed 3.'
    default:
      return ''
  }
}
const columnSort:any=(colDef:any,order:string[])=>{
  const result:any=[]
  order.forEach(key=>{      
   const index =colDef.findIndex((col:any)=>col.field==key)
   if(index>=0){
    result.push(colDef[index])
   }
  })
  return result;
} 

export {
  fileDownload,
  checkError,
  returnColumnDef,
  removeSpace,
  isObjectEmpty,
  encryptData,
  decryptData,
  workpaperActionItems,
  moreFRActions,
  dateTimeFormatter,
  dateConverter,
  dateConverterToDDMMYYYY,
  getDateFormat,
  getRecipientDisabledStatus,
  getRecipientDisabledMessage,
  columnSort
};
function getHeader(val: any) {
  return displayNames[val] ? displayNames[val] : camelCaseToTitleCase(val);
}
